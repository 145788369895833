<template>
  <div class="please-add-us">
    <div class="beg-message">
      Thanks for checking out <strong>reveal.chat!</strong> This app is in BETA, so please share it with your friends! <br /><br />
      No one here yet? Sign up for notifications 🔔 and add to your home screen 🏠 and we'll tell you when people are online 👾
    </div>
    <div class="actions-container">
      <button :disabled="!notificationsSupported || isSubscribed" @click="setUpPushNotifications" class="action-btn" :style="subscriptionButtonStyles">
        <template v-if="!isSubscribed && notificationsSupported">
          🔔 Subscribe
        </template>
        <template v-else-if="isSubscribed && notificationsSupported">
          🔔 Subscribed!
        </template>
        <template v-if="!notificationsSupported">
          🔔 Subscribe (N/A)
        </template>
      </button>
      <button :disabled="!addToHomeScreenSupported || isAppInstalled" @click="addToHomeScreen" class="action-btn" :style="addToHomeScreenButtonStyles">
        <template v-if="isAppInstalled">
          🏠 Added!
        </template>
        <template v-else-if="!isAppInstalled && addToHomeScreenSupported">
          🏠 Add
        </template>
        <template v-else>
          🏠 Add (N/A)
        </template>
      </button>
    </div>
    <!-- <a target="_blank" href="https://shareasale.com/r.cfm?b=1331247&amp;u=1952563&amp;m=73372&amp;urllink=&amp;afftrack="><img src="https://static.shareasale.com/image/73372/300x60_new2.jpg" border="0" alt="Puffy Lux" /></a> -->
    <div class="status">
      <slot name="status"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

const pushSubscriptionServer = 'https://backend.reveal.chat/subscribe';

const isSubscribed = ref(false);
const isAppInstalled = ref(false);
const addToHomeScreenSupported = ref(false);
const notificationsSupported = computed(() => 'Notification' in window);
let deferredPrompt;
let notificationPermission;
if (notificationsSupported.value) {
  notificationPermission = ref(Notification.permission);
}

const subscriptionButtonStyles = computed(() => {
  const styles = {};
  let backgroundColor;
  let cursor;
  let opacity = 1;
  if (isSubscribed.value) {
    backgroundColor = 'green';
    cursor = 'not-allowed';
    opacity = 0.7;
  } else if (!notificationsSupported.value) {
    backgroundColor = 'gray';
    cursor = 'not-allowed';
    opacity = 0.7;
  } else {
    backgroundColor = 'fuschia';
    cursor = 'pointer';
  }
  styles.backgroundColor = backgroundColor;
  styles.cursor = cursor;
  styles.opacity = opacity;
  return styles;
});
const addToHomeScreenButtonStyles = computed(() => {
  const styles = {};
  let backgroundColor;
  let cursor;
  let opacity = 1;
  if (isAppInstalled.value) {
    backgroundColor = 'green';
    cursor = 'not-allowed';
    opacity = 0.7;
  } else if (!addToHomeScreenSupported.value) {
    backgroundColor = 'gray';
    cursor = 'not-allowed';
    opacity = 0.7;
  } else {
    backgroundColor = 'fuschia';
    cursor = 'pointer';
  }
  styles.backgroundColor = backgroundColor;
  styles.cursor = cursor;
  styles.opacity = opacity;
  return styles;
});

const subscribeToNotifications = async () => {
  const registration = await navigator.serviceWorker.ready
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(
      'BMlRfjsGr83-RoyNmeQcsxisn2XHB7i4xtElUiPQFnu-sMq5DXPQnWZhehKph08-eIZaPacayjIqx8eMrodNzSA'
    )
  })
  // Send subscription object to the server
  await axios.post(pushSubscriptionServer, subscription)
  if (subscription) {
    isSubscribed.value = true
    // write this to local storage
    localStorage.setItem('isSubscribed', true)
  }
}

const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const setUpPushNotifications = async () => {
  if (isSubscribed.value) {
    return;
  }
  try {
    if (Notification.permission === 'default') {
      const permission = await Notification.requestPermission();
      notificationPermission.value = permission;
      if (permission !== 'granted') {
        console.log('Notification permission not granted');
        return;
      }
    } else if (Notification.permission === 'denied') {
      console.log('Notification permission denied');
      return;
    } else {
      notificationPermission.value = 'granted';
    }

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      await subscribeToNotifications();
    }
  } catch (error) {
    console.error('Error setting up push notifications', error);
  }
}

const addToHomeScreen = async () => {
  if (!addToHomeScreenSupported.value) return;

  // Show the prompt
  deferredPrompt.prompt();

  // Wait for the user to respond to the prompt
  const choiceResult = await deferredPrompt.userChoice;
  if (choiceResult.outcome === 'accepted') {
    console.log('User accepted the A2HS prompt');
    isAppInstalled.value = true;
  } else {
    console.log('User dismissed the A2HS prompt');
  }
  // We no longer need the prompt. Clear it up.
  deferredPrompt = null;
};

onMounted(()=> {
  const subscribed = localStorage.getItem('isSubscribed')
  if (subscribed === 'true') {
    isSubscribed.value = true
  } else {
    isSubscribed.value = false
  }
  // Check if the app was installed in a previous session
  isAppInstalled.value = localStorage.getItem('isAppInstalled') === 'true';
  window.addEventListener('beforeinstallprompt', (e) => {
    console.log('beforeinstallprompt fired');
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    addToHomeScreenSupported.value = true;
  });
  window.addEventListener('appinstalled', () => {
    console.log('App was installed.');
    localStorage.setItem('isAppInstalled', 'true');
    isAppInstalled.value = true;
  });

  // Check if app is running in standalone mode
  if (window.matchMedia('(display-mode: standalone)').matches) {
    console.log('App is running in standalone mode.');
    isAppInstalled.value = true;
  }
})
</script>

<style scoped>
.please-add-us {
  max-width: 600px;
  margin: auto;
  padding: .5rem;
  text-align: center;
}

.beg-message {
  margin-bottom: 20px;
  line-height: 1.2;
  font-size: 1rem;
}

.actions-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  flex-direction: row; /* Default to side-by-side */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .beg-message {
  margin-bottom: 20px;
  line-height: 1.2;
  font-size: .8rem;
}
}

.action-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-btn:hover {
  background-color: #0056b3;
}

.action-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.status {
  color: green;
  font-weight: bold;
  font-size: 1.5rem;
}
</style>
