<template>
  <div class="app-container">
    <h1>
      <VueWriter :array="['reveal.chat']" :iterations="1" :typeSpeed="90" />
    </h1>
    <header ref="headerRef">
      <div v-if="isDesktop" class="users-online">
        <IconGroup fill="yellowgreen" />
        <span class="user-count">{{ usersOnline }}</span>
      </div>
    </header>

    <main>
      <div class="video-container">
        <div class="video-wrapper">
          <!-- <MaterialButton
            @click="triggerFileInput"
            icon-name="image"
            :size="isDesktop ? 'dt' : 'mb'"
            class="floating-button left-icon image-picker-button"
            >Choose Background</MaterialButton
          > -->
          <input
            type="file"
            ref="fileInput"
            accept="image/*"
            @change="handleFileChange"
            style="display: none"
          />
          <div v-if="!isDesktop" class="users-online-video">
            <IconGroup fill="yellowgreen" />
            <span class="user-count">{{ usersOnline }}</span>
          </div>
          <canvas
            class="mediapipe-canvas blurred"
            ref="canvasElement"
            :style="canvasStyle"
          ></canvas>
          <video
            ref="localVideo"
            class="video-feed blurred"
            :style="videoStyle"
            autoplay
            playsinline
            muted
          ></video>
          <button v-if="localStream" class="mic-button" @click="() => toggleMute('local')">
            <IconMicOff v-if="localMicMuted" fill="red" />
            <IconMicOn v-else fill="green" />
          </button>
          <div
            v-if="cameraPermission !== 'granted'"
            @click="startCamera"
            class="setup-camera clickable"
            data-event="enable-camera"
          >
            Enable Camera<br />📸
          </div>
        </div>
        <div class="video-wrapper">
          <video ref="remoteVideo" class="video-feed blurred" autoplay playsinline muted></video>
          <!-- <IconPenis class="nsfw-icon" :fill="maybeNsfw ? 'red' : 'green'" height="24px" width="24px"/> -->
          <button v-if="remoteVideo" class="mute-button" @click="() => toggleMute('remote')">
            <IconVolumeOff v-if="remoteAudioMuted" fill="red" />
            <IconVolumeUp v-else fill="green" />
          </button>
          <div class="status-container">
            <PleaseAddUs class="placeholder-message" v-if="userState !== 'connected'">
              <template v-slot:status>
                <div v-if="cameraPermission !== 'granted'" @click="startCamera" class="clickable">
                  Please Enable Your Camera 📸
                </div>
                <div
                  v-else-if="userState === 'purgatory' && gotKilled"
                  @click="startChat"
                  class="clickable"
                >
                  You got ghosted 👻<br />Click ▶️ to chat!
                </div>
                <div
                  v-else-if="userState === 'purgatory' && !gotKilled"
                  @click="startChat"
                  class="clickable"
                >
                  Click ▶️ to chat!
                </div>
                <div v-else-if="userState === 'connecting' && !autoReconnect" class="waiting-msg">
                  Waiting for someone...
                </div>
              </template>
            </PleaseAddUs>
          </div>
        </div>
      </div>
    </main>
    <MaterialButton
      v-if="!isRevealed && cameraPermission === 'granted'"
      @click="handleReveal"
      icon-name="frame-person"
      :size="isDesktop ? 'dt' : 'mb'"
      class="floating-button left-icon"
      data-event="reveal"
      >Reveal</MaterialButton
    >
    <MaterialButton
      v-if="isRevealed && cameraPermission === 'granted'"
      @click="handleHide"
      icon-name="hide"
      :size="isDesktop ? 'dt' : 'mb'"
      class="floating-button left-icon"
      data-event="hide"
      >Hide</MaterialButton
    >
    <MaterialButton
      v-if="userState === 'connected'"
      @click="handleKillCall"
      icon-name="close"
      :size="isDesktop ? 'dt' : 'mb'"
      button-color="red"
      class="floating-button right-icon"
      data-event="skip"
      >Skip</MaterialButton
    >
    <MaterialButton
      v-else-if="userState === 'connecting'"
      icon-name="hourglass"
      :size="isDesktop ? 'dt' : 'mb'"
      button-color="orange"
      class="floating-button right-icon"
      >Waiting</MaterialButton
    >
    <MaterialButton
      v-else-if="cameraPermission === 'granted' && userState === 'purgatory'"
      @click="startChat"
      icon-name="play"
      :size="isDesktop ? 'dt' : 'mb'"
      button-color="green"
      class="floating-button right-icon"
      data-event="start-call"
      >Start Call</MaterialButton
    >
  </div>
</template>

<script setup>
import adapter from 'webrtc-adapter';
import { v4 as uuidv4 } from 'uuid'
import { ref, reactive, onMounted, onBeforeUnmount, computed, watch } from 'vue'
import MaterialButton from '@/components/MaterialButton.vue'
// import axios from 'axios'
import IconMicOff from './icons/IconMicOff.vue'
import IconMicOn from './icons/IconMicOn.vue'
import IconGroup from './icons/IconGroup.vue'
import IconVolumeUp from './icons/IconVolumeUp.vue'
import IconVolumeOff from './icons/IconVolumeOff.vue'
import PleaseAddUs from './PleaseAddUs.vue'
import IconPenis from './icons/IconPenis.vue';

// let model;
// const maybeNsfw = ref(false)

// const initModel = async () => {
//   if (model) {
//     return
//   }
//   model = await window.nsfwjs.load()
// }

let intervalId = null;
const delay = 5000; // Delay in milliseconds (e.g., 5000 ms for 5 seconds)

// async function classifyImage() {
//   if (!isRemoteStreamConnected.value) {
//     return
//   }
//   try {
//     const predictions = await model.classify(remoteVideo.value);
//     // search array for prediction with className 'Porn'
//     const pornPrediction = predictions.find(p => p.className === 'Porn')
//     const threshold = 0.25
//     if (pornPrediction.probability > threshold) {
//       maybeNsfw.value = true
//       console.log('NSFW', pornPrediction.probability)
//     } else {
//       maybeNsfw.value = false
//     }

//     console.log('Predictions:', predictions);
//   } catch (error) {
//     console.error('Error classifying image:', error);
//   }
// }

// function startClassificationLoop() {
//   intervalId = setInterval(async () => {
//     await classifyImage();
//   }, delay);
// }

// function stopClassificationLoop() {
//   if (intervalId) {
//     clearInterval(intervalId);
//   }
// }


console.log(adapter.browserDetails.browser)

const webSocketServer = 'wss://backend.reveal.chat'
// const webSocketServer = 'ws://localhost:3000';

const breakpoint = 768

const appHeight = ref(window.innerHeight)
const handleResize = () => {
  appHeight.value = window.innerHeight
  windowWidth.value = window.innerWidth
}
const myUniqueID = uuidv4()
const localVideo = ref(null)
const remoteVideo = ref(null)
const localStream = ref(null)
let peerConnection = new RTCPeerConnection({
  iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
})
const isRevealed = ref(false)
const windowWidth = ref(window.innerWidth)
const isDesktop = computed(() => windowWidth.value >= breakpoint)
const localMicMuted = ref(true)
const remoteAudioMuted = ref(true)
const usersOnline = ref(0)
const isRemoteStreamConnected = ref(false)
const canvasElement = ref(null)
let selfieSegmentation
const isSegmentationAvailable = ref(false)
const isCustomBackground = ref(false)
const selectedBackgroundName = ref('')
const backgroundImageRef = ref(null)
const headerRef = ref(null)
const userState = ref('purgatory') // purgatory, waiting, connecting, connected
const gotKilled = ref(false)
let pairedUserId = null
const stopBlinking = ref(false)
const autoReconnect = ref(false)
const cameraPermission = ref('')

const mediaPipeSettings = reactive({
  selfieMode: true,
  modelSelection: 0,
  effect: 'mask'
})

const fileInput = ref(null)

const triggerFileInput = () => {
  fileInput.value.click() // Programmatically click the hidden file input
}

const startRemoteVideo = () => {
  if (remoteVideo.value) {
    remoteVideo.value.play().catch((e) => {
      console.error('Error starting video playback: ', e)
    })
  }
}

async function handleFileChange(event) {
  isCustomBackground.value = true
  const file = event.target.files[0]
  selectedBackgroundName.value = file.name
  loadBackgroundImage(file)
  try {
    if (localVideo.value) {
      await localVideo.value.play().catch((e) => {
        console.error('Error starting video playback: ', e)
      })
    }
  } catch (playbackError) {
    console.error('Error playing video:', playbackError)
  }
}

let isBackgroundImageLoaded = false

// Computed properties for styles
const videoStyle = computed(() => ({
  display: isCustomBackground.value && isSegmentationAvailable.value ? 'none' : 'block'
}))

const canvasStyle = computed(() => ({
  display: isCustomBackground.value && isSegmentationAvailable.value ? 'block' : 'none'
}))

watch(isCustomBackground, (newValue) => {
  if (newValue) {
    // If the custom background is enabled, start processing
    processVideoFrame()
    replaceVideoTrackWithCanvas()
  } else {
    replaceVideoTrackWithOriginal()
    // If disabled, you might want to clear the canvas or handle it as needed
    const canvasCtx = canvasElement.value.getContext('2d')
    canvasCtx.clearRect(0, 0, canvasElement.value.width, canvasElement.value.height)
  }
})

function getCanvasStream() {
  if (canvasElement.value) {
    return canvasElement.value.captureStream(30) // 30 FPS, adjust as needed
  }
  return null
}

function replaceVideoTrackWithCanvas() {
  const canvasStream = getCanvasStream()
  if (!canvasStream) {
    console.error('Canvas stream is not available.')
    return
  }

  const videoTrack = canvasStream.getVideoTracks()[0]

  // Replace the track in each sender that sends video
  peerConnection.getSenders().forEach((sender) => {
    if (sender.track && sender.track.kind === 'video') {
      sender.replaceTrack(videoTrack)
    }
  })
}

function replaceVideoTrackWithOriginal() {
  if (localStream.value) {
    const originalVideoTrack = localStream.value.getVideoTracks()[0]
    peerConnection.getSenders().forEach((sender) => {
      if (sender.track && sender.track.kind === 'video') {
        sender.replaceTrack(originalVideoTrack)
      }
    })
  }
}

function loadBackgroundImage(file) {
  const reader = new FileReader()
  reader.onload = (e) => {
    backgroundImageRef.value = new Image()
    backgroundImageRef.value.onload = () => {
      // Set the flag to true to indicate the background image is loaded
      isBackgroundImageLoaded = true
    }
    backgroundImageRef.value.src = e.target.result // Set the new image as the background
  }
  reader.readAsDataURL(file)
}

const toggleMute = (videoType) => {
  if (videoType === 'local') {
    localMicMuted.value = !localMicMuted.value
    localStream.value.getAudioTracks()[0].enabled = !localMicMuted.value
  } else {
    remoteAudioMuted.value = !remoteAudioMuted.value
    remoteVideo.value.muted = remoteAudioMuted.value
  }
}

const setUserState = (state) => {
  userState.value = state
}

// TODO:move the peer connection init to a new method so this
// can be used
// const transceivers = peerConnection.getTransceivers()
// transceivers.forEach((transceiver) => {
//   const codecs = RTCRtpSender.getCapabilities('video').codecs
//   const h264Codec = codecs.find((codec) => codec.mimeType === 'video/H264')
//   if (h264Codec) {
//     transceiver.setCodecPreferences([h264Codec])
//   }
// })
let signalServerWs // WebSocket connection

const init = async () => {
  // Set initial state to 'purgatory'
  setUserState('purgatory')
  // Connect to signaling server
  setUpWebSocket()
  // Set up an RTCPeerConnection
  resetWebRTCConnection()
  if (navigator.permissions && navigator.permissions.query) {
    const cameraStatus = await navigator.permissions.query({ name: 'camera' })
    cameraPermission.value = cameraStatus.state
    // Ensure we have a local stream set up, and any permissions necessary
    if (cameraPermission.value === 'granted') {
      startCamera()
    }
  } else {
    // If the browser doesn't support navigator.permissions, we assume it supports media devices
    startCamera()
  }
}

const startCamera = async () => {
  await getLocalStream()
  // setupMediaPipe()
}

const startChat = async () => {
  // Set state to 'connecting'
  setUserState('connecting')
  gotKilled.value = false
  // Send message to server to indicate we're ready to pair
  sendMessage('ready-to-pair')
}

const handlePairedMsg = async ({ pairedUserId: remoteUserId }) => {
  pairedUserId = remoteUserId
  console.log('paired user id', pairedUserId)
  // create and send offer
  const offer = await peerConnection.createOffer()
  console.log('offer created', offer)
  await peerConnection.setLocalDescription(offer)
  sendMessage('offer', { offer, pairedUserId: remoteUserId })
}

const handleKillCall = async () => {
  setUserState('purgatory')
  sendMessage('kill-call', { pairedUserId })
  pairedUserId = null
  handleHide()
  resetWebRTCConnection()
}

const handlePing = () => {
  sendMessage('ping-response')
}

const getLocalStream = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
    cameraPermission.value = 'granted'
    localVideo.value.srcObject = stream
    localStream.value = stream
    localStream.value.getAudioTracks()[0].enabled = !localMicMuted.value

    localVideo.value.addEventListener('loadeddata', async () => {
      await adjustCanvasSize()
      processVideoFrame()
    })
    stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream))
  } catch (error) {
    console.error('Error accessing media devices:', error)
  }
}

onMounted(async () => {
  handleResize()
  window.addEventListener('resize', handleResize)
  await init()
  if (!isDesktop.value) {
    setTimeout(() => {
      headerRef.value.classList.add('shrink')
    }, 1000)
  }
  setTimeout(() => {
    stopBlinking.value = true
  }, 2000)
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.get('autoReconnect') === 'true') {
    autoReconnect.value = true
  }
  // const initDickFilter = async () =>  {
  //   await initModel()
  //   startClassificationLoop()
  // }
  // initDickFilter()
})

async function adjustCanvasSize() {
  const video = localVideo.value
  const aspectRatio = video.videoHeight / video.videoWidth
  let width, height

  if (window.innerWidth > window.innerHeight) {
    height = window.innerHeight
    width = height / aspectRatio
  } else {
    width = window.innerWidth
    height = width * aspectRatio
  }

  canvasElement.value.width = width
  canvasElement.value.height = height
  console.log(`Canvas size adjusted: width = ${width}, height = ${height}`)
}

async function processVideoFrame() {
  // Turn the current frame into an Image object
  // const image = new Image()
  // image.src = localVideo.value.toDataURL('image/png')
  // classifyImage()
  if (!isCustomBackground.value) {
    // Skip processing if custom background is not enabled
    return
  }
  // Send the video frame to MediaPipe for processing
  if (localVideo.value && selfieSegmentation) {
    try {
      await selfieSegmentation.send({ image: localVideo.value })
    } catch (error) {
      console.error('Error processing video frame:', error)
    }
  }

  // Continue processing the next frame
  requestAnimationFrame(processVideoFrame)
}

function setupMediaPipe() {
  // Initialize MediaPipe with video and canvas elements
  selfieSegmentation = new window.SelfieSegmentation({
    locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@0.1/${file}`
  })

  selfieSegmentation.onResults(onResults)
  selfieSegmentation.setOptions({
    selfieMode: mediaPipeSettings.selfieMode,
    modelSelection: mediaPipeSettings.modelSelection
  })
  console.log('MediaPipe setup complete.')
}

function onResults(results) {
  if (!isCustomBackground.value) {
    // Skip drawing if custom background is not enabled
    return
  }
  // Create an off-screen canvas for mask processing
  const maskCanvas = document.createElement('canvas')
  maskCanvas.width = canvasElement.value.width
  maskCanvas.height = canvasElement.value.height
  const maskCtx = maskCanvas.getContext('2d')

  // Draw the segmentation mask onto the off-screen canvas
  maskCtx.drawImage(
    results.segmentationMask,
    0,
    0,
    results.segmentationMask.width,
    results.segmentationMask.height,
    0,
    0,
    maskCanvas.width,
    maskCanvas.height
  )

  // Process the mask to shrink edges and add an alpha channel
  const maskImageData = maskCtx.getImageData(0, 0, maskCanvas.width, maskCanvas.height)
  for (let y = 0; y < maskImageData.height; y++) {
    for (let x = 0; x < maskImageData.width; x++) {
      const index = (y * maskImageData.width + x) * 4
      // Adjust the threshold as needed
      if (maskImageData.data[index] < 255) {
        maskImageData.data[index + 3] = maskImageData.data[index] // Set alpha
      }
      // Shrink the mask edges
      if (maskImageData.data[index] > 0 && maskImageData.data[index] < 255) {
        maskImageData.data[index + 3] = maskImageData.data[index] * 0.5 // Reduce alpha
      }
    }
  }
  maskCtx.putImageData(maskImageData, 0, 0)

  const canvasCtx = canvasElement.value.getContext('2d')
  canvasCtx.clearRect(0, 0, canvasElement.value.width, canvasElement.value.height)

  // Draw the user's video frame
  canvasCtx.drawImage(
    results.image,
    0,
    0,
    results.image.width,
    results.image.height,
    0,
    0,
    canvasElement.value.width,
    canvasElement.value.height
  )

  // Apply the mask to cut out the user's shape from the video frame
  canvasCtx.globalCompositeOperation = 'destination-in'
  canvasCtx.drawImage(
    maskCanvas,
    0,
    0,
    maskCanvas.width,
    maskCanvas.height,
    0,
    0,
    canvasElement.value.width,
    canvasElement.value.height
  )

  // Draw the background behind the existing canvas content
  canvasCtx.globalCompositeOperation = 'destination-over'
  if (isBackgroundImageLoaded) {
    canvasCtx.drawImage(
      backgroundImageRef.value,
      0,
      0,
      canvasElement.value.width,
      canvasElement.value.height
    )
  } else {
    canvasCtx.fillStyle = '#000000'
    canvasCtx.fillRect(0, 0, canvasElement.value.width, canvasElement.value.height)
  }
  // Reset the globalCompositeOperation for future operations
  canvasCtx.globalCompositeOperation = 'source-over'
  isSegmentationAvailable.value = true
}

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

function resetWebRTCConnection() {
  if (peerConnection) {
    peerConnection.close()
  }
  peerConnection = new RTCPeerConnection({
    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
  })
  setUpPeerConnection()

  localStream.value = localVideo.value.srcObject
  if (localStream.value) {
    localStream.value
      .getTracks()
      .forEach((track) => peerConnection.addTrack(track, localStream.value))
  }
  if (remoteVideo.value) {
    remoteVideo.value.srcObject = null
    // Set isRemoteStreamConnected to false to show the 'Waiting' message
    isRemoteStreamConnected.value = false
  }
}

function setUpWebSocket() {
  signalServerWs = new WebSocket(webSocketServer)
  signalServerWs.onopen = () => {
    sendMessage('register')
  }
  signalServerWs.onclose = () => {
    sendMessage('im-leaving')
  }
  signalServerWs.onmessage = (event) => {
    const data = JSON.parse(event.data)
    switch (data.type) {
      case 'reveal':
        handleRemoteReveal()
        break
      case 'hide':
        handleRemoteHide()
        break
      case 'offer':
        handleOfferMsg(data)
        break
      case 'answer':
        handleAnswerMsg(data.answer)
        break
      case 'ice-candidate':
        handleNewICECandidate(data.candidate)
        break
      case 'paired':
        handlePairedMsg(data)
        break
      case 'kill-call':
        handleCallKilled()
        break
      case 'user-count':
        usersOnline.value = data.count
        break
      case 'ping':
        handlePing()
        break
    }
  }
}

async function handleCallKilled() {
  // Set state to 'purgatory'
  gotKilled.value = true
  setUserState('purgatory')
  handleHide()
  // Reset peer connection
  resetWebRTCConnection()
  if (autoReconnect.value) {
    // If auto reconnect is enabled, start a new chat
    await startChat()
  }
}

function sendMessage(type, content = {}) {
  signalServerWs.send(JSON.stringify({ type, userId: myUniqueID, ...content }))
}

function setUpPeerConnection() {
  peerConnection.ontrack = (event) => {
    remoteVideo.value.srcObject = event.streams[0]
    isRemoteStreamConnected.value = true
    startRemoteVideo()
    if (!isDesktop.value) {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }

  peerConnection.onicecandidate = (event) => {
    if (event.candidate) {
      sendMessage('ice-candidate', { candidate: event.candidate, pairedUserId })
    }
  }

  peerConnection.oniceconnectionstatechange = () => {
    if (peerConnection.iceConnectionState === 'disconnected') {
      sendMessage('other-party-left', { pairedUserId })
      handleCallKilled()
    }
  }
}

async function handleOfferMsg({ offer, pairedUserId: remoteUserId }) {
  setUserState('connected')
  pairedUserId = remoteUserId
  await peerConnection.setRemoteDescription(new RTCSessionDescription(offer))
  const answer = await peerConnection.createAnswer()
  await peerConnection.setLocalDescription(answer)
  sendMessage('answer', { answer, pairedUserId })
}

async function handleAnswerMsg(answer) {
  setUserState('connected')
  await peerConnection.setRemoteDescription(new RTCSessionDescription(answer))
}

async function handleNewICECandidate(candidate) {
  try {
    await peerConnection.addIceCandidate(new RTCIceCandidate(candidate))
  } catch (error) {
    console.error('Error adding received ice candidate', error)
  }
}

const handleReveal = () => {
  if (pairedUserId) {
    sendMessage('reveal', { pairedUserId })
  }
  isRevealed.value = true
  localVideo.value.classList.remove('blurred')
  canvasElement.value.classList.remove('blurred')
}
const handleHide = () => {
  if (pairedUserId) {
    console.log('still paired, sending hide message')
    sendMessage('hide', { pairedUserId })
  }
  isRevealed.value = false
  localVideo.value.classList.add('blurred')
  canvasElement.value.classList.add('blurred')
}
const handleRemoteReveal = () => {
  remoteVideo.value.classList.remove('blurred')
}
const handleRemoteHide = () => {
  remoteVideo.value.classList.add('blurred')
}
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent vertical scrolling */
}

header {
  position: relative; /* To position the pseudo-element */
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 10px;
  overflow: hidden; /* To hide the shrinking background */
}

header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  z-index: -1; /* Ensures h1 is above the background */
}

h1 {
  display: block;
  text-align: center;
  margin: 0;
  position: absolute; /* Ensures h1 is above the background */
  z-index: 100;
  width: 100%;
}

@keyframes shrinkBackground {
  from {
    height: 100%;
  }
  to {
    height: 0;
  }
}

.shrink {
  animation: shrinkBackground 1s ease forwards; /* 'forwards' keeps the final state */
}

.users-online {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Aligns content to the right */
}
.notif-icon {
  display: flex;
  align-items: center;
  justify-content: center; /* Aligns content to the right */
  line-height: 1;
  background: none;
  border: none;
  cursor: pointer;
  outline: inherit;
}

.icon-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
}

h1 {
  margin: 0;
  font-size: 1.5em;
}

main {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.video-wrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.mute-button {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  z-index: 100;
}
.mic-button {
  background-color: transparent;
  border: none;
  position: absolute;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  z-index: 100;
}
.status-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  z-index: 500;
}

.placeholder-message {
  color: white;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  text-align: center;
}

.material-symbols-outlined {
  color: white; /* Adjust color as needed */
  font-size: 24px; /* Adjust size as needed */
}

.users-online,
.users-online-video {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  z-index: 100;
}

.users-online-video {
  position: absolute;
  top: 10px;
  right: 5px;
}

.user-count {
  color: yellowgreen;
  margin-left: 5px;
}

.video-feed {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adjust as necessary */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9; /* Below the canvas */
  transform: scaleX(-1);
}

.mediapipe-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10; /* Ensure it's above other elements */
}

.blurred {
  transition: filter 1s ease;
  filter: blur(15px) sepia(100%);
}
:not(.blurred) {
  transition: filter 1s ease;
  filter: none;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  z-index: 1000;
}

.left-icon {
  left: 20px;
}

.right-icon {
  right: 20px;
}

.hamburger-menu {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hamburger-menu .material-symbols-outlined {
  font-size: 24px; /* Adjust size as needed */
  color: white; /* Adjust color as needed */
}

.close-sidebar {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: white;
}

.side-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Full width on mobile */
  max-width: 400px; /* Maximum width on desktop */
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent dark background */
  color: white; /* Text color */
  z-index: 1000; /* Ensure it's above other content */
  overflow-y: auto; /* Enable scrolling if content overflows */
  padding: 20px;
  box-sizing: border-box;
  transform: translateX(-100%); /* Start off-screen */
  transition: transform 0.3s ease-in-out;
}

.side-panel.open {
  transform: translateX(0); /* Slide in */
}

.image-picker-button {
  position: absolute;
  top: 10px; /* Adjust based on your layout */
  left: 10px; /* Align with other buttons */
  z-index: 1000;
  transform: scale(0.5); /* Reduces the size to 50% of the original */
  transform-origin: top left;
}

@media (min-width: 768px) {
  .video-container {
    flex-direction: row;
  }
  .mute-button {
    top: 10px;
  }
  .mic-button {
    top: 10px;
    right: 10px;
    bottom: unset;
    left: unset;
  }
  .side-panel {
    width: 100%; /* Auto width on desktop */
  }
}

@media (max-width: 767px) {
  /* .image-picker-button {
    top: 20px;
  } */
  main {
    align-items: start;
  }

  .video-container {
    flex-direction: column;
    height: calc(100vh - 40px); /* Full viewport height minus header height */
  }

  .video-wrapper {
    height: 50%; /* Each video wrapper takes half the remaining height */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .video-feed {
    width: 100%; /* Fill the wrapper width */
    height: 100%; /* Fill the wrapper height */
    object-fit: cover; /* Ensures the video covers the entire area of the wrapper */
  }

  .status-container {
    top: 32px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  /* Styles for floating buttons */
  .floating-button {
    width: 60px;
    height: 60px;
    bottom: 10px;
  }
}
.clickable {
  cursor: pointer;
}
.waiting-msg {
  color: orange;
}
.setup-camera {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
}
.nsfw-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 400;
}
</style>
