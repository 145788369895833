<template>
  <button class="material-button" :style="{ backgroundColor: buttonColor }" @click="onClick">
    <component :is="iconComponent" />
  </button>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import IconMicOff from './icons/IconMicOff.vue';
import IconMicOn from './icons/IconMicOn.vue';
import IconPlay from './icons/IconPlay.vue';
import IconFramePerson from './icons/IconFramePerson.vue';
import IconGroup from './icons/IconGroup.vue';
import IconHide from './icons/IconHide.vue';
import IconHourglass from './icons/IconHourglass.vue';
import IconClose from './icons/IconClose.vue';

const props = defineProps({
  iconName: {
    type: String,
    required: true
  },
  buttonText: {
    type: String,
    required: false,
    default: ''
  },
  buttonColor: {
    type: String,
    required: false,
    default: 'fuchsia'
  },
  size: {
    type: String,
    required: false,
    default: 'dt'
  }
});

const iconComponent = computed(() => {
  switch (props.iconName) {
    case 'mic-off': return IconMicOff;
    case 'mic-on': return IconMicOn;
    case 'play': return IconPlay;
    case 'frame-person': return IconFramePerson;
    case 'group': return IconGroup;
    case 'hide': return IconHide;
    case 'hourglass': return IconHourglass;
    case 'close': return IconClose;
    default: return IconMicOff;
  }
});

const emit = defineEmits(['click']);

const fontSize = computed(() => {
  switch (props.size) {
    case 'dt': return '60px';
    case 'sm': return '20px';
    default: return '40px';
  }
});

const onClick = () => {
  emit('click');
};
</script>

<style scoped>
.material-button {
  background-color: fuchsia; /* Circle color */
  border: none;
  border-radius: 50%; /* Circle shape */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  padding: 10px;
  width: 100px; /* Circle size */
  height: 100px; /* Circle size */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24; /* Larger icon size */
  color: white; /* Icon color */
}

svg {
  height: 100%;
  width: 100%;
  fill: white;
}
</style>
